import React, { ReactElement } from "react";
import ThreeDayCallIcon from "../../../utils/components/icons/homepage/ThreeDayCallIcon";
import WhatIfsIcon from "../../../utils/components/icons/homepage/WhatIfsIcon";
import CompanyManagementIcon from "../../../utils/components/icons/homepage/CompanyManagementIcon";
import DailyPaceIcon from "../../../utils/components/icons/homepage/DailyPaceIcon";
import BusinessPlanningIcon from "../../../utils/components/icons/homepage/BusinessPlanningIcon";
import ReportsIcon from "../../../utils/components/icons/homepage/ReportsIcon";
import BetaIcon from "../../../utils/components/icons/BetaIcon";
import {
  Access,
  AppsEnum,
  BpwAccessType,
  empty,
  MpwAccessType,
} from "@nexstar-network/shared-utils";
import DigitalMetricsIcon from "../../../utils/components/icons/homepage/DigitalMetricsIcon";
import ProfitReportIcon from "../../../utils/components/icons/homepage/ProfitReportIcon";
import FinancialSurveyIcon from "../../../utils/components/icons/homepage/FinancialSurveyIcon";
import ProfitPricerIcon from "../../../utils/components/icons/homepage/ProfitPricerIcon";
import MPWIcon from "../../../utils/components/icons/homepage/MPWIcon";
import ExportIcon from "../../../utils/components/icons/homepage/ExportIcon";
import ImportIcon from "../../../utils/components/icons/homepage/ImportIcon";
import { userCanAccessFinancials } from "../../../utils/helpers/UserHelper";

export const identifierName = (link: string): string =>
  `button_go_to_${link.replace("/", "")}`;
// TP-296: JAH added Beta badge conditionally to this
export const chooseActionIcon = (
  icon: string,
  isBeta: boolean,
  disabled: boolean,
): ReactElement => {
  if (icon === "PROFITPRICER") {
    return (
      <div>
        <ProfitPricerIcon color={disabled ? "gray" : "NexstarLimeDark"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </div>
    );
  }
  if (icon === "THREEDAYCALL") {
    return (
      <>
        <ThreeDayCallIcon color={disabled ? "gray" : "NexstarSkyBlue"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </>
    );
  }
  if (icon === "WHATIFS") {
    return (
      <div>
        <WhatIfsIcon color={disabled ? "gray" : "NexstarLimeDark"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </div>
    );
  }
  if (icon === "COMPANYMANAGEMENT") {
    return (
      <div>
        <CompanyManagementIcon color={disabled ? "gray" : "NexstarSoftGold"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </div>
    );
  }
  if (icon === "IMPORT") {
    return (
      <div>
        <ImportIcon color={disabled ? "gray" : "NexstarSoftGold"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </div>
    );
  }
  if (icon === "EXPORT") {
    return (
      <div>
        <ExportIcon color={disabled ? "gray" : "NexstarSoftGold"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </div>
    );
  }
  if (icon === "REPORTS") {
    return (
      <div>
        <ReportsIcon color={disabled ? "gray" : "NexstarSoftGold"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </div>
    );
  }
  if (icon === "DAILYPACE") {
    return (
      <>
        <DailyPaceIcon color={disabled ? "gray" : "NexstarSkyBlue"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </>
    );
  }
  if (icon === "BPW") {
    return (
      <div>
        <BusinessPlanningIcon color={disabled ? "gray" : "NexstarOrange"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </div>
    );
  }
  if (icon === "DMT") {
    return (
      <div>
        <DigitalMetricsIcon color={disabled ? "gray" : "NexstarBlueLight"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </div>
    );
  }
  if (icon === "MPW") {
    return (
      <div>
        <MPWIcon color={disabled ? "gray" : "NexstarBlueLight"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </div>
    );
  }
  if (icon === "PROFITREPORT") {
    return (
      <div>
        <ProfitReportIcon color={disabled ? "gray" : "NexstarLimeDark"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </div>
    );
  }
  if (icon === "FINANCIALSURVEY") {
    return (
      <div>
        <FinancialSurveyIcon color={disabled ? "gray" : "NexstarLimeDark"} />
        {isBeta ? (
          <div className="-ml-3 -mt-1.5">
            <BetaIcon color="NexstarOrange" />
          </div>
        ) : null}
      </div>
    );
  }
};

export type ToolDataFEObjectType = {
  icon: string;
  toolEnum: string;
  label: string;
  disabledDescription: string;
  link: string;
  toolType: string;
};
export type typeToolFEDataType = Record<
  | AppsEnum
  | "BPW_PREWORK"
  | "BPW_WORKSHOP"
  | "BPW_TRACKING"
  | "MPW_PREWORK"
  | "MPW_PLANNING"
  | "MPW_TRACKING",
  ToolDataFEObjectType
>;
export const toolData: typeToolFEDataType = {
  THREEDAYCALL: {
    icon: "THREEDAYCALL",
    toolEnum: "THREEDAYCALL",
    label: "3 Day Call",
    disabledDescription:
      "To enable this tool, you must complete fiscal year :year: in Company Setup.",
    link: "/3daycallboard",
    toolType: "dailyTools",
  },
  ACTIONPLAN: {
    icon: "ACTIONPLAN",
    toolEnum: "ACTIONPLAN",
    label: "Action Plan",
    disabledDescription:
      "To enable this tool, you must complete :year: Business Planning Pre-Work.",
    link: "/business-planning/:year:/actionplan",
    toolType: "BPW",
  },
  BPW: {
    icon: "BPW",
    toolEnum: "BPW",
    label: "Business Planning Workshop",
    disabledDescription:
      "To enable this tool, you must complete fiscal year :year: in Company Setup.",
    link: "/business-planning",
    toolType: "BPW",
  },
  BPW_PREWORK: {
    icon: "BPW_PREWORK",
    toolEnum: "BPW_PREWORK",
    label: "Pre-Work",
    disabledDescription:
      "Business Planning Pre-Work will be available to fill out 2 months prior to your BPW event date. To enable this tool, you must complete fiscal year :year: in Company Setup.",
    link: "/business-planning/:year:/prework",
    toolType: "BPW",
  },
  BPW_WORKSHOP: {
    icon: "BPW_WORKSHOP",
    toolEnum: "BPW_WORKSHOP",
    label: "Workshop",
    disabledDescription:
      "To enable this tool, you must complete :year: Business Planning Pre-Work.",
    link: "/business-planning/:year:/workshop",
    toolType: "BPW",
  },
  BPW_TRACKING: {
    icon: "BPW_TRACKING",
    toolEnum: "BPW_TRACKING",
    label: "Tracking",
    disabledDescription:
      "To enable this tool, you must complete and finalize :year: Business Planning Workshop.",
    link: "/business-planning/:year:/tracking",
    toolType: "BPW",
  },
  COMPANYMANAGEMENT: {
    icon: "COMPANYMANAGEMENT",
    toolEnum: "COMPANYMANAGEMENT",
    label: "Company Setup",
    disabledDescription: null,
    link: "/management/:year:/overview",
    toolType: "companyManagement",
  },
  DAILYPACE: {
    icon: "DAILYPACE",
    toolEnum: "DAILYPACE",
    label: "Daily Pace",
    disabledDescription:
      "To enable this tool, you must complete fiscal year :year: in Company Setup.",
    link: "/dailypace",
    toolType: "dailyTools",
  },
  IMPORT: {
    icon: "IMPORT",
    toolEnum: "IMPORT",
    label: "Imports",
    disabledDescription:
      "To enable this tool, you must complete a fiscal year in Company Setup.",
    link: "/imports",
    toolType: "companyManagement",
  },
  EXPORT: {
    icon: "EXPORT",
    toolEnum: "EXPORT",
    label: "Exports",
    disabledDescription:
      "To enable this tool, you must complete a fiscal year in Company Setup.",
    link: "/exports",
    toolType: "companyManagement",
  },
  PROFITPRICER: {
    icon: "PROFITPRICER",
    toolEnum: "PROFITPRICER",
    label: "Profit Pricer",
    disabledDescription: null,
    link: "/profitpricer",
    toolType: "financial",
  },
  REPORTS: {
    icon: "REPORTS",
    toolEnum: "REPORTS",
    label: "Company Reporting",
    disabledDescription:
      "To enable this tool, you must complete a Business Planning Workshop.",
    link: "/reporting",
    toolType: "companyManagement",
  },
  WHATIFS: {
    icon: "WHATIFS",
    toolEnum: "WHATIFS",
    label: "What Ifs Calculator",
    disabledDescription:
      "To enable this tool, you must complete a Profit Pricer",
    link: "/whatifs",
    toolType: "financial",
  },
  DMT: {
    icon: "DMT",
    toolEnum: "DMT",
    label: "Digital Metrics Tracker",
    disabledDescription:
      "To enable this tool, you must complete fiscal year :year: in Company Setup.",
    link: "/marketing/:year:/digital-metrics",
    toolType: "marketing",
  },
  MPW: {
    icon: "MPW",
    toolEnum: "MPW",
    label: "Marketing Planner",
    disabledDescription:
      "To enable this tool, you must be signed up for a Marketing Planning event.",
    link: "/marketing-planning/",
    toolType: "marketing",
  },
  MPW_PREWORK: {
    icon: "MPW_PREWORK",
    toolEnum: "MPW_PREWORK",
    label: "Pre-Work",
    disabledDescription:
      "Marketing Planning Pre-Work will be available to fill out 2 months prior to your event date. To enable this tool, you must complete fiscal year :year: in Company Setup.",
    link: "/marketing-planning/:year:/prework",
    toolType: "MPW",
  },
  MPW_PLANNING: {
    icon: "MPW_PLANNING",
    toolEnum: "MPW_PLANNING",
    label: "Planning",
    disabledDescription:
      "To enable this tool, you must complete :year: Marketing Planning Pre-Work.",
    link: "/marketing-planning/:year:/planning",
    toolType: "MPW",
  },
  MPW_TRACKING: {
    icon: "MPW_PLANNING",
    toolEnum: "MPW_PLANNING",
    label: "Tracking",
    disabledDescription:
      "To enable this tool, you must complete :year: Marketing Planning.",
    link: "/marketing-planning/:year:/tracking",
    toolType: "MPW",
  },
  PROFITREPORT: {
    icon: "PROFITREPORT",
    toolEnum: "PROFITREPORT",
    label: "Profit Report",
    disabledDescription:
      "To enable this tool, you must complete fiscal year :year: in Company Setup.",
    link: "/profitreport/:year:",
    toolType: "financial",
  },
  FINANCIALSURVEY: {
    icon: "FINANCIALSURVEY",
    toolEnum: "FINANCIALSURVEY",
    label: "Financial Survey",
    disabledDescription:
      "To enable this tool, you must complete fiscal year :year: in Company Setup.",
    link: "/financial-survey/:year:",
    toolType: "financial",
  },
  ADMIN: {
    icon: null,
    toolEnum: "ADMIN",
    label: "Admin",
    disabledDescription: null,
    link: null,
    toolType: null,
  },
  CHARTS: {
    icon: null,
    toolEnum: "CHARTS",
    label: "Charts",
    disabledDescription: null,
    link: null,
    toolType: null,
  },
};

export const toolParents = {
  WHATIFS: "PROFITPRICER",
  THREEDAYCALL: "COMPANYMANAGEMENT",
  DAILYPACE: "COMPANYMANAGEMENT",
  DMT: "COMPANYMANAGEMENT",
  PROFITREPORT: "COMPANYMANAGEMENT",
  FINANCIALSURVEY: "COMPANYMANAGEMENT",
  MPW: "MPW",
};

export const shouldDisableTool = (
  toolEnum: string,
  userToolAccess: Access,
  workshopEvents: { bpw: BpwAccessType; mpw: MpwAccessType },
): boolean => {
  // Do you have access to the tool?
  if (toolEnum in toolParents) {
    if (toolParents[toolEnum] === "BPW") {
      if (!empty(workshopEvents.bpw)) {
        // JAH: I know that this seems like its the opposite but it works.
        let bpwCompleted = false;
        Object.values(workshopEvents.bpw).forEach((bpwEvent) => {
          if (bpwEvent.status.workshop.complete) bpwCompleted = true;
        });
        return bpwCompleted;
      }
      return false;
    }
    if (toolParents[toolEnum] === "MPW") {
      return !empty(workshopEvents.mpw);
    }
    if (
      toolParents[toolEnum] in userToolAccess &&
      userToolAccess[toolParents[toolEnum]].complete
    ) {
      return true;
    }
    return false;
  }
  if (
    toolEnum === "COMPANYMANAGEMENT" ||
    toolEnum === "PROFITPRICER" ||
    (toolEnum in userToolAccess && userToolAccess[toolEnum].complete) ||
    (toolEnum in userToolAccess && userToolAccess[toolEnum].access)
  ) {
    return true;
  }

  // You do not have access.
  return false;
};

export const canAccessTool = (
  toolEnum: string,
  userToolAccess: Record<string, any>,
): boolean => {
  // Do you have access to the tool?
  if (
    userToolAccess &&
    toolEnum in userToolAccess &&
    userToolAccess[toolEnum]?.access
  ) {
    return true;
  }
  // You do not have access.
  return false;
};

export const shouldDisplayTool = (
  userToolAccess,
  companyOptions,
  bpwAccess,
  user,
  bpwEvents?,
) => {
  const toolsObject = {};
  Object.entries(toolData).map(([toolEnum, toolInfo]) => {
    // TP-1660: Adding in a condition to remove these from the sidebar
    if (
      !["ADMIN", "ACTIONPLAN", "CHARTS"].includes(toolEnum) &&
      canAccessTool(toolEnum, userToolAccess)
    ) {
      if (
        toolEnum !== "REPORTS" &&
        toolEnum !== "PROFITREPORT" &&
        toolEnum !== "FINANCIALSURVEY"
      ) {
        toolsObject[toolEnum] = toolInfo.label;
      }
      // check for Reporting access
      if (toolEnum === "REPORTS" && (companyOptions.length > 1 || bpwAccess)) {
        toolsObject[toolEnum] = toolInfo.label;
      }
      // check for Profit Report access from CRM
      if (toolEnum === "PROFITREPORT") {
        if (userCanAccessFinancials(user)) {
          toolsObject[toolEnum] = toolInfo.label;
        }
      }
      // check for Financial Survey access from CRM
      if (toolEnum === "FINANCIALSURVEY") {
        if (userCanAccessFinancials(user)) {
          toolsObject[toolEnum] = toolInfo.label;
        }
      }
      // check for BPW access
      if (toolEnum === "BPW" && (bpwEvents as BpwAccessType)) {
        if (
          !empty(bpwEvents) &&
          (bpwEvents as BpwAccessType) &&
          userCanAccessFinancials(user)
        ) {
          toolsObject[toolEnum] = toolInfo.label;
        }
      }
    }
  });
  return toolsObject;
};
